/*
  Style for Archives
*/

#archives {
  letter-spacing: 0.03rem;

  $timeline-width: 4px;

  %timeline {
    content: "";
    width: $timeline-width;
    position: relative;
    float: left;
    background-color: var(--timeline-color);
  }

  .year {
    height: 3.5rem;
    font-size: 1.5rem;
    margin-left: -$timeline-width;

    &::before {
      @extend %timeline;

      height: 72px;
      left: 72px;
      bottom: 16px;
    }

    &:first-child::before {
      @extend %timeline;

      height: 32px;
      top: 24px;
    }

    &::after { /* Year dot */
      content: "";
      display: inline-block;
      position: relative;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      left: 14.5px;
      border: 3px solid;
      background-color: var(--timeline-year-dot-color);
      border-color: var(--timeline-node-bg);
      box-shadow: 0 0 2px 0 #c2c6cc;
      z-index: 1;
    }

  }

  ul {
    li {
      font-size: 1.1rem;
      line-height: 3rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      a {
        /* post title in Archvies */
        margin-left: 2.5rem;
        position: relative;
        top: 0.1rem;

        &:hover {
          border-bottom: none;
        }
      }

      &:nth-child(odd) {
        background-color: var(--main-wrapper-bg, #fff);
        background-image: linear-gradient(to left, #fff, #fbfbfb, #fbfbfb, #fbfbfb, #fff);
      }

      &::before {
        @extend %timeline;

        top: 0;
        left: 68px;
        height: 3rem;
      }
    }

    &:last-child li:last-child::before {
      height: 1.5rem;
      top: -12px;
    }

  } /* #archives ul */

  .date {
    white-space: nowrap;
    display: inline-block;

    &.month {
      width: 1.4rem;
      text-align: center;

      ~ a::before {
        /* A dot for Month and Day */
        content: "";
        display: inline-block;
        position: relative;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        float: left;
        top: 1.35rem;
        right: 21.5px;
        background-color: var(--timeline-node-bg);
        box-shadow: 0 0 3px 0 #c2c6cc;
        z-index: 1;
      }
    }

    &.day {
      font-size: 85%;
      font-family: 'Lato', sans-serif;
      text-align: center;
      margin-right: -2px;
      width: 1.2rem;
      position: relative;
      left: -0.15rem;
    }
  } // #archives .date

} // #archives

@media all and (max-width: 576px) {
  #archives {
    margin-top: -1rem;

    ul {
      letter-spacing: 0;
    }
  }
}
